<template>
  <v-container>
      <v-row>
          <v-col cols=12 lg=5>
              <s-crud title="Usuarios"  search-input
              :config=configUser  @save=save($event)  @rowSelected=rowSelected($event)>

              </s-crud>
          </v-col>
          <v-col v-if=item>
              <s-toolbar label="Permisos" search-input @removed="deleteEvent()"  remove excel ></s-toolbar>
              <v-card elevation="0">
                  <v-col>
                  <v-row justify="center">
                        <v-col lg=10 class="pb-1" cols=12>
                            <s-select-area 
                                autofocus 
                                ref='usrcce' 
                                clearable 
                                label="Centro de Costo"  
                                centercost 
                                v-model=item.CceID /> 
                        </v-col>
                        <v-col lg=6 class="pt-0 " cols=12>
                            <s-select-business-line 
                                clearable 
                                label="Linea de Costo" 
                                :cceID='item.CceID==null?0:item.CceID' 
                                v-model=item.BslID /> 
                        <!--item.CceID==null?0:item.CceID-->
                        <!--$fun.hasSpecialPermission('AllLine')==true? 1 : 0-->
                        </v-col>
                        <v-col lg=4 class="pt-0 " cols=12> 
                            <s-select-definition 
                                :def="1045"  
                                label="Tipo de Costo" 
                                v-model=item.TypeCost />
                             
                        </v-col>
                  </v-row>
                  <v-row justify="center">
                      <v-col class="pt-1" lg=5 ><v-btn @click='item={};$refs.usrcce.focus();' block small>Nuevo</v-btn></v-col>
                        <v-col class="pt-1" lg=5 ><v-btn @click=save(item) block small color="primary">Guardar</v-btn></v-col>
                  </v-row>
                  </v-col>
              </v-card>
              <v-data-table  show-select 
              :items=itemsPermit dense item-key="PerID"
              :headers=this.config.headers v-model=selectedPermit :single-select="true"
              >
              </v-data-table>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import _sPermit from "@/services/Logistics/PermitService";
import _sUser from "@/services/Logistics/UserService";
export default {
    components: {  },
    props: {
    
    
    },
    data: () => ({itemsUsers:[],itemsPermit:[],item:null,selectedPermit:[],
        config:{
            model:{PerID:'ID',CceID:'int'},
            headers:[
            {text:'Centro de Costo',value:'CceName'},
            {text:'Linea de Negocio',value:'BslName'},
            {text:'Tipo de Costo',value:'TypeCostName'},
            ],

            service:_sPermit
        },
        configUser:{
            model:{UsrID:'ID'},
            headers:[{text:'ID',value:'UsrID'},
            {text:'Nombre',value:'NtpFullName'},
            {text:'Usuario',value:'UsrName'},
            ],

            service:_sUser
        }
    }),

    watch: {
    },
    methods: {
        rowSelected(items){  
            if(items.length>0){
                this.item=items[0];
                this.item.TypeCost=1;
    
                    _sPermit.byUser({UsrID:this.item.UsrID},this.$fun.getUserID()).then((r)=>{
                            this.itemsPermit=r.data;
                            this.$nextTick().then(()=>{
                                this.$refs.usrcce.focus();
                            }) 
                    })
            }

            else this.item=null;
        },
        deleteEvent(){
            if(this.selectedPermit.length>0){
                console.log(this.selectedPermit);
                this.selectedPermit[0].SecStatus=0;
                _sPermit.save(this.selectedPermit[0],this.$fun.getUserID()) .then((r)=>{
                    _sPermit.byUser({UsrID:this.item.UsrID},this.$fun.getUserID()).then((r)=>{
                        this.itemsPermit=r.data;
                        this.$nextTick().then(()=>{
                            this.$refs.usrcce.focus();
                        }) 
                    })
                });
            }
        },
        save(item){
            if(!item.CceID){this.$fun.alert('Falta Centro de Costo','warning'); return;}
            if(!item.BslID){this.$fun.alert('Falta Linea de Negocio','warning'); return;}
            if(!item.TypeCost){this.$fun.alert('Falta Tipo de Costo','warning'); return;}
    
                
            _sPermit.save(this.item,this.$fun.getUserID()) .then((r)=>{
                _sPermit.byUser({UsrID:this.item.UsrID},this.$fun.getUserID()).then((r)=>{
                    this.itemsPermit=r.data;
                    this.item.CceID=0;
                    this.item.TypeCost=1;
                    this.$nextTick().then(()=>{
                        this.$refs.usrcce.focus();
                    }) 
                })
        });
        }
   },
   created(){
       _sPermit.users(this.$fun.getUserID()).then((r)=>{
           this.itemsUsers=r.data;
       })
   },
   mounted(){
        console.log("permiso de allline", this.$fun.hasSpecialPermission('AllLine'))
   }
};
</script>
