import Service from "../Service";
import Vue from "vue";
const resource = "logistics/user/";

export default {
  
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
};